import {
  AccountBalance,
  ArrowRightAlt,
  Assignment,
  AttachMoney,
  Bookmarks,
  CalendarToday,
  CompareArrows,
  Computer,
  ConfirmationNumber,
  ContactSupport,
  Contacts,
  Dashboard,
  Description,
  DeviceHub,
  Directions,
  DirectionsRun,
  Dns,
  DonutSmall,
  DynamicFeed,
  EmojiEvents,
  Equalizer,
  Explore,
  Facebook,
  FolderOpen,
  FormatListNumbered,
  GridOn,
  ImportContacts,
  LocalActivity,
  LocalConvenienceStore,
  LocalHospital,
  LocationCity,
  ManageAccounts,
  Map,
  MoneyOff,
  Numbers,
  Pageview,
  Person,
  PersonAdd,
  PersonOutline,
  PersonPinCircle,
  PhotoLibrary,
  PlaylistAddCheck,
  Policy,
  Receipt,
  RecordVoiceOver,
  School,
  Security,
  Send,
  Speed,
  Store,
  SupervisorAccount,
  TableChart,
  TrendingDown,
  TrendingUp,
  VideoLibrary,
  Wallet,
  Whatshot,
  WifiTethering,
  YouTube,
} from '@mui/icons-material';
import PictureAsPdf from '@mui/icons-material/PictureAsPdf';
import { ReactComponent as ContestIcon } from '../../assets/elephant-thailand.svg';
import { ReactComponent as ITSIcon } from '../../assets/its-logo.svg';
import {
  AWS_GROUP,
  BillingPermissionData,
  BillingUserAccess,
} from '../../types';

export interface NavDataLink {
  title: string;
  // Only this Group can see this link
  hasGroup?: AWS_GROUP;
  // User must have one of the permissions
  permissions?: AWS_GROUP[];
  // to - for internal routing
  to?: string;
  // href - for external links
  href?: string;
  startIcon?: JSX.Element;
  children?: NavDataLink[];
  name?: BillingPermissionData['Name'];
  access?: BillingUserAccess[];
}

export interface NavDataSection {
  title: string;
  // Only this Group can see this link
  hasGroup?: AWS_GROUP;
  // User must have one of the permissions
  permissions?: AWS_GROUP[];
  links: NavDataLink[];
  name?: BillingPermissionData['Name'];
  access?: BillingUserAccess[];
}

export const navData: NavDataSection[] = [
  {
    title: 'My Business',
    links: [
      {
        title: 'Commissions',
        to: '/business/commissions',
        startIcon: <TrendingUp fontSize="small" />,
      },
      {
        title: 'Debts',
        to: '/business/debts',
        startIcon: <TrendingDown fontSize="small" />,
      },
      {
        title: 'My Wallet',
        href: 'https://thealliancecontracting.com/wallet',
        startIcon: <Wallet fontSize="small" />,
      },
      {
        title: 'My Profile',
        href: 'https://thealliancecontracting.com/account',
        startIcon: <Person fontSize="small" />,
      },
      {
        title: 'Contracting',
        href: 'https://thealliancecontracting.com/home',
        startIcon: <Policy fontSize="small" />,
      },
      {
        title: 'My Carrier Writing #s',
        href: 'https://thealliancecontracting.com/writing-numbers',
        startIcon: <Numbers fontSize="small" />,
      },
      {
        title: 'CRM',
        to: '/business/crm',
        startIcon: <Contacts fontSize="small" />,
      },
      {
        title: 'Leads',
        startIcon: <PersonPinCircle fontSize="small" />,
        children: [
          {
            title: 'The Alliance Leads',
            href: 'https://theallianceleads.com/',
          },
          {
            title: 'Integrity Lead Center',
            href: 'https://www.integrityleadcenter.com/login',
          },
        ],
      },
      {
        title: 'My Dashboard',
        startIcon: <Equalizer fontSize="small" />,
        children: [
          {
            title: 'Sales',
            to: '/business/dashboard/sales',
          },
          {
            title: 'Recruiting',
            to: '/business/dashboard/recruiting',
          },
          {
            title: '$ by Direct Leg',
            to: '/business/dashboard/direct-leg',
          },
          {
            title: '$ Agent List',
            to: '/business/dashboard/agent-list',
          },
          {
            title: 'My $ Chart',
            to: '/business/dashboard/deposit-summary',
          },
          {
            title: 'Team Sales',
            to: '/business/dashboard/team-sales',
          },
          {
            title: 'Volume by Direct Leg',
            to: '/business/dashboard/volume-by-direct-leg',
          },
          {
            title: 'Recruiting by Direct Leg',
            to: '/business/dashboard/recruiting-by-direct-leg',
          },
        ],
      },
      {
        title: 'My Activity',
        to: '/business/activity-tracker',
        startIcon: (
          <WifiTethering
            fontSize="small"
            style={{ transform: 'rotate(145deg)' }}
          />
        ),
      },
      {
        title: 'My Reports',
        to: '/business/reports',
        startIcon: <FolderOpen fontSize="small" />,
      },
      {
        title: 'E-Apps',
        to: '/business/eapps-by-carrier',
        startIcon: <Assignment fontSize="small" />,
      },
    ],
  },
  {
    title: 'Team',
    links: [
      {
        title: 'New Agent Registration',
        href: 'https://register.naaleads.com/',
        startIcon: <PersonAdd fontSize="small" />,
      },
      {
        title: 'National Convention 2025',
        href: 'https://theallianceevents.com/events/national-convention-2025',
        startIcon: <LocalConvenienceStore fontSize="small" />,
      },
      {
        title: 'Team Events Tickets',
        to: '/team/events',
        startIcon: <ConfirmationNumber fontSize="small" />,
      },
      // {
      //   title: 'Team Activity Tracker',
      //   to: '/team/activity-tracker',
      //   startIcon: <People fontSize="small" />,
      // },
      {
        title: 'Thailand Leaderboard',
        to: '/team/contest-leaderboard',
        startIcon: (
          <ContestIcon fontSize="small" style={{ height: 20, width: 20 }} />
        ),
      },
      {
        title: 'Hierarchy Production Drawing',
        to: '/team/hierarchy-production-drawing',
        startIcon: <DeviceHub fontSize="small" />,
      },
      {
        title: 'Hierarchy List',
        to: '/team/hierarchy',
        startIcon: <FormatListNumbered fontSize="small" />,
      },
      {
        title: 'New Recruit Info',
        to: '/team/recruit',
        startIcon: <PersonOutline fontSize="small" />,
      },
      {
        title: 'Personal and Team Calendars',
        to: '/team/calendar',
        startIcon: <CalendarToday fontSize="small" />,
      },
    ],
  },
  {
    title: 'Carriers',
    links: [
      {
        title: 'Online Contracting',
        href: 'https://thealliancecontracting.com/',
        startIcon: <ArrowRightAlt fontSize="small" />,
      },
    ],
  },
  {
    title: 'Resources',
    links: [
      {
        title: 'Welcome ARC Videos',
        to: '/resources/welcome-videos',
        startIcon: <VideoLibrary fontSize="small" />,
      },
      {
        title: 'Agent Levels',
        href: 'https://a02a41c004c75ffe8d91-b19a28bfebdacc37eb56f25bc81c2f2f.ssl.cf1.rackcdn.com/documents/arcnaaleads/header/Agent Levels Chart v2023-05-01 1307 p1_2.pdf',
        startIcon: <TableChart fontSize="small" />,
      },
      {
        title: 'SuperPac',
        href: 'https://a02a41c004c75ffe8d91-b19a28bfebdacc37eb56f25bc81c2f2f.ssl.cf1.rackcdn.com/documents/arcnaaleads/header/SuperPAC-2023-05-01.pdf',
        startIcon: <AttachMoney fontSize="small" />,
      },
      {
        title: '100% Grid',
        href: 'https://a02a41c004c75ffe8d91-b19a28bfebdacc37eb56f25bc81c2f2f.ssl.cf1.rackcdn.com/documents/arcnaaleads/header/PayoutGrid_121924.pdf',
        startIcon: <GridOn fontSize="small" />,
      },
      {
        title: 'Alliance Underwriting Tool',
        href: 'https://allianceunderwriting.com/home-page',
        startIcon: <Description fontSize="small" />,
      },
      {
        title: 'Contest Flyers',
        to: '/resources/contest-flyers',
        startIcon: <Map fontSize="small" />,
      },
      {
        title: 'New Agent Guide',
        href: 'https://online.fliphtml5.com/thgjn/rcea/',
        startIcon: <Directions fontSize="small" />,
      },
      {
        title: 'Get Started Fast',
        href: 'https://naauniversity.com/courses/getting-started-quickly',
        startIcon: <DirectionsRun fontSize="small" />,
      },
      {
        title: 'Alliance University',
        href: 'https://naauniversity.com/naau-advanced-resources/',
        startIcon: <LocalActivity fontSize="small" />,
      },
      {
        title: 'Quarterly Magazine',
        href: 'https://online.fliphtml5.com/thgjn/bhrl/',
        startIcon: <ImportContacts fontSize="small" />,
      },
      {
        title: 'E & O Insurance',
        href: 'http://www.calsurance.com/naa',
        startIcon: <Security fontSize="small" />,
      },
      {
        title: 'Pre-Licensing',
        to: '/resources/pre-licensing',
        startIcon: <PlaylistAddCheck fontSize="small" />,
      },
      {
        title: 'Continuing Education',
        href: 'https://www.webce.com/naaleads/',
        startIcon: <School fontSize="small" />,
      },
      {
        title: 'Alliance Rx Card',
        href: 'https://www.therxcard.net/HWBBKK',
        startIcon: <LocalHospital fontSize="small" />,
      },
      {
        title: 'AHIP Certification ',
        href: 'https://a02a41c004c75ffe8d91-b19a28bfebdacc37eb56f25bc81c2f2f.ssl.cf1.rackcdn.com/documents/arcnaaleads/header/AHIP_Training_Guide_2024.pdf',
        startIcon: <Bookmarks fontSize="small" />,
      },
      {
        title: 'New York Products',
        to: '/resources/ny-products',
        startIcon: <LocationCity fontSize="small" />,
      },
      {
        title: 'Legacy Safeguard Program',
        href: 'https://www.legacysafeguarduniversity.com',
        startIcon: <Computer fontSize="small" />,
      },
      {
        title: 'Legacy Safeguard Presentation',
        href: 'https://a02a41c004c75ffe8d91-b19a28bfebdacc37eb56f25bc81c2f2f.ssl.cf1.rackcdn.com/documents/arcnaaleads/header/Legacy-Safeguard-Presentation.pdf',
        startIcon: <PictureAsPdf fontSize="small" />,
      },
      {
        title: 'FAQ',
        to: '/resources/faq',
        startIcon: <ContactSupport fontSize="small" />,
      },
    ],
  },
  {
    title: 'Connections',
    links: [
      {
        title: 'Alliance Hotspots',
        href: 'https://theallianceevents.com/hotspots',
        startIcon: <Whatshot fontSize="small" />,
      },
      {
        title: 'Alliance Facebook',
        href: 'https://www.facebook.com/groups/330822977953654',
        startIcon: <Facebook fontSize="small" />,
      },
      {
        title: 'Alliance YouTube',
        href: 'https://www.youtube.com/channel/UCcTxkFiu-XtuLjH5VpnwBMg',
        startIcon: <YouTube fontSize="small" />,
      },
      {
        title: 'ARC Commissions Team',
        href: 'https://thealliancearc.simplybook.me/v2/',
        startIcon: <AttachMoney fontSize="small" />,
      },
      {
        title: 'Integrity Advisory Solutions',
        href: 'https://a02a41c004c75ffe8d91-b19a28bfebdacc37eb56f25bc81c2f2f.ssl.cf1.rackcdn.com/documents/arcnaaleads/header/Business_Summit_Flyer_20241122r.pdf',
        startIcon: <DonutSmall fontSize="small" />,
      },
      {
        title: 'Lead Performance Team',
        href: 'https://lpt.simplybook.me/v2/#book',
        startIcon: <Speed fontSize="small" />,
      },
      {
        title: "President's Club",
        href: 'https://www.naapresidentsclub.com/',
        startIcon: <EmojiEvents fontSize="small" />,
      },
      {
        title: 'Gina Hawks',
        href: 'https://outlook.office365.com/owa/calendar/GinaHawksIULAppointmentsCalendar@integritymarketing.com/bookings/',
        startIcon: <Person fontSize="small" />,
      },
      {
        title: 'Ivy Wilson',
        href: 'https://ivywilson.simplybook.me/',
        startIcon: <Person fontSize="small" />,
      },
      {
        title: 'AndyAlbright.com',
        href: 'https://www.andyalbright.com/',
        startIcon: <RecordVoiceOver fontSize="small" />,
      },
      {
        title: 'The Alliance Store',
        href: 'https://shopthealliance.com/',
        startIcon: <Store fontSize="small" />,
      },
      {
        title: 'ITS (CRN # 830795) / Estate Planning',
        to: '/connections/estate-planning',
        startIcon: <ITSIcon style={{ height: 20, width: 20 }} />,
      },
      {
        title: 'Alliance Eventures',
        href: 'https://theallianceevents.com/',
        startIcon: <Explore fontSize="small" />,
      },
      {
        title: 'AmpPix',
        href: 'https://www.amppix.com/',
        startIcon: <PhotoLibrary fontSize="small" />,
      },
    ],
  },
  {
    title: 'Admin',
    permissions: [
      AWS_GROUP.ReadAll,
      AWS_GROUP.Staff_ReadOnly,
      AWS_GROUP.Accounting,
      AWS_GROUP.CarrierFeeds,
      AWS_GROUP.Commissions,
      AWS_GROUP.CommissionApproval,
      AWS_GROUP.Marketing,
      AWS_GROUP.ARC_BillingSystem,
      AWS_GROUP.Contracting,
      AWS_GROUP.LDS_KeyingMgr,
      AWS_GROUP.PermissionsAdmin,
      AWS_GROUP.Reconciliation,
    ],
    links: [
      {
        startIcon: <ManageAccounts fontSize="small" />,
        title: 'Agent',
        to: '/admin/agent',
        permissions: [
          AWS_GROUP.ReadAll,
          AWS_GROUP.Contracting,
          AWS_GROUP.AgentDataAdmin,
          AWS_GROUP.Accounting,
          AWS_GROUP.Commissions,
          AWS_GROUP.CommissionApproval,
          AWS_GROUP.Staff_ReadOnly,
        ],
      },
      {
        title: 'Carrier Feeds',
        to: '/admin/carrier-feeds',
        startIcon: <DynamicFeed fontSize="small" />,
        permissions: [
          AWS_GROUP.ReadAll,
          AWS_GROUP.GlobalAdmin,
          AWS_GROUP.CarrierFeeds,
          AWS_GROUP.Commissions,
          AWS_GROUP.CommissionApproval,
        ],
      },
      {
        title: 'Bank Reconciliation',
        to: '/admin/bank-reconciliation',
        startIcon: <CompareArrows fontSize="small" />,
        permissions: [AWS_GROUP.ReadAll, AWS_GROUP.Reconciliation],
      },
      {
        title: 'Policy Manager',
        to: '/admin/policy-manager',
        startIcon: <Policy fontSize="small" />,
        permissions: [
          AWS_GROUP.ReadAll,
          AWS_GROUP.Commissions,
          AWS_GROUP.CommissionApproval,
          AWS_GROUP.Accounting,
        ],
      },
      {
        title: 'Matrix Manager',
        to: '/admin/matrix-manager',
        startIcon: <Dns fontSize="small" />,
        permissions: [AWS_GROUP.ReadAll, AWS_GROUP.CommissionMatrix],
      },
      {
        title: 'Policy Lookup',
        to: '/admin/policy-lookup',
        startIcon: <Pageview fontSize="small" />,
        permissions: [
          AWS_GROUP.ReadAll,
          AWS_GROUP.Commissions,
          AWS_GROUP.CommissionApproval,
          AWS_GROUP.Accounting,
        ],
      },
      {
        title: 'Accounting',
        startIcon: <AccountBalance fontSize="small" />,
        permissions: [AWS_GROUP.ReadAll, AWS_GROUP.Accounting],
        children: [
          {
            to: '/admin/accounting/nacha',
            title: 'NACHA Manager',
            permissions: [AWS_GROUP.ReadAll, AWS_GROUP.Accounting],
          },
          {
            to: '/admin/accounting/cb-manager',
            title: 'Percent CB Manager',
            permissions: [AWS_GROUP.ReadAll, AWS_GROUP.Accounting],
          },
          {
            to: '/admin/accounting/adjustments',
            title: 'Adjustments',
            permissions: [AWS_GROUP.ReadAll, AWS_GROUP.Accounting],
          },
          {
            to: '/admin/accounting/debts',
            title: 'Debts',
            permissions: [AWS_GROUP.ReadAll, AWS_GROUP.Accounting],
          },
          {
            to: '/admin/accounting/reports',
            title: 'Reports',
            permissions: [AWS_GROUP.ReadAll, AWS_GROUP.Accounting],
          },
          {
            to: '/admin/accounting/ach-returns',
            title: 'ACH Returns',
            permissions: [AWS_GROUP.ReadAll, AWS_GROUP.Accounting],
          },
          {
            to: '/admin/accounting/1099-report',
            title: '1099 Report',
            permissions: [AWS_GROUP.ReadAll, AWS_GROUP.Accounting],
          },
        ],
      },
      {
        title: 'CMS',
        to: '/admin/cms',
        startIcon: <Dashboard fontSize="small" />,
        permissions: [AWS_GROUP.ReadAll, AWS_GROUP.Marketing],
      },
      {
        title: 'Permissions',
        to: '/admin/permissions',
        startIcon: <SupervisorAccount fontSize="small" />,
        permissions: [
          AWS_GROUP.ReadAll,
          AWS_GROUP.GlobalAdmin,
          AWS_GROUP.PermissionsAdmin,
        ],
      },
      {
        title: 'Mass Communication',
        to: '/admin/mass-communication',
        startIcon: <Send fontSize="small" />,
        permissions: [AWS_GROUP.ReadAll, AWS_GROUP.Marketing],
      },
      {
        title: 'Lead Billing Suspensions',
        to: '/admin/lead-billing-suspensions',
        startIcon: <MoneyOff fontSize="small" />,
        permissions: [
          AWS_GROUP.ReadAll,
          AWS_GROUP.Staff_ReadOnly,
          AWS_GROUP.Accounting,
          AWS_GROUP.Commissions,
          AWS_GROUP.CommissionApproval,
        ],
      },
      {
        title: 'Billing',
        startIcon: <Receipt fontSize="small" />,
        hasGroup: AWS_GROUP.ARC_BillingSystem,
        children: [
          {
            title: 'Accounts Receivable',
            children: [
              {
                title: 'Accounts Receivable',
                to: '/admin/billing/accounts-receivable',
              },
              {
                title: 'Adjustment Approval',
                to: '/admin/billing/adjustment-approval',
                name: 'AR Adjustment',
                access: [BillingUserAccess['Allowed']],
              },
              {
                title: 'Payment Plan Management',
                to: '/admin/billing/payment-plan-management',
                name: 'Payment Plan Management',
                access: [
                  BillingUserAccess['Read-Only'],
                  BillingUserAccess['Read/Write'],
                ],
              },
            ],
          },
          {
            title: 'Drafts',
            children: [
              {
                title: 'Draft Adjustment Approvals',
                to: '/admin/billing/draft-adjustment-approvals',
                name: 'Draft Adjustment Approval',
                access: [BillingUserAccess['Allowed']],
              },
              {
                title: 'Create ACH',
                to: '/admin/billing/create-ach',
                name: 'Drafts',
                access: [BillingUserAccess['Allowed']],
              },
              {
                title: 'Post ACH',
                to: '/admin/billing/post-ach',
                name: 'Drafts',
                access: [BillingUserAccess['Allowed']],
              },
              {
                title: 'Credit Cards',
                to: '/admin/billing/credit-cards',
                name: 'Drafts',
                access: [BillingUserAccess['Allowed']],
              },
            ],
          },
          {
            title: 'Invoicing',
            children: [
              {
                title: 'Generate Invoices',
                to: '/admin/billing/generate-invoices',
                name: 'Invoicing',
                access: [BillingUserAccess['Allowed']],
              },
              {
                title: 'Invoice Line Items',
                to: '/admin/billing/invoice-line-items',
                name: 'Invoice Line Items',
                access: [
                  BillingUserAccess['Read-Only'],
                  BillingUserAccess['Read/Write'],
                ],
              },
            ],
          },
          {
            title: 'Reports',
            to: '/admin/billing/reports',
            access: [BillingUserAccess['Allowed']],
          },
          {
            title: 'Tools',
            children: [
              {
                title: 'Rollups',
                to: '/admin/billing/rollups',
                name: 'AR: Edit/Remove Entries',
                access: [BillingUserAccess['Allowed']],
              },
              {
                title: 'User Administration',
                to: '/admin/billing/user-administration',
                name: 'User Administration',
                access: [
                  BillingUserAccess['Read-Only'],
                  BillingUserAccess['Read/Write'],
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
